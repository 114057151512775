.park-info {
  position: relative;
  padding: 2.5em 0;

  &__title {
    font-family: $font-family-secondary;
    font-weight: $font-weight-bold;
    font-size: 2.25em;
    line-height: 120%;
    text-transform: uppercase;
    word-spacing: 100vw;

    @include max-width( $breakpoint-md ) {
      font-size: 1.5em;
      position: absolute;
      top: 2.5rem;
      left: 0;
    }
  }

  &__info-wrapper {
    display: flex;
    align-items: center;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include max-width( $breakpoint-md ) {
      flex-direction: column;
      text-align: right;
    }

    .info-block {
      @include max-width( $breakpoint-md ) {
        margin-bottom: 1.5rem;
      }

      &__title {
        display: inline-block;
        position: relative;
        font-family: $font-family-secondary;
        font-weight: $font-weight-bold;
        font-size: 0.875em;
        line-height: 100%;
        text-transform: uppercase;
        letter-spacing: 0.15em;

        .title-icon {
          position: absolute;
          top: -6px;
          right: -32px;

          @include max-width( $breakpoint-md ) {
            svg {
              display: none;
            }
          }

          &:hover {
            svg {
              fill: $color-link-hover;
            }
          }
        }
      }

      &__content {
        margin: .5em 0 0;
        font-size: 1em;
        line-height: 1.375rem;

        a {
          color: $color-link;
          font-weight: $font-weight-semibold;
          text-decoration: none;

          &:hover {
            color: $color-link-hover;
          }
        }
      }

      &__button {
        height: auto;
        padding: .75rem 1.5rem;
        font-family: $font-family-secondary;
        font-weight: $font-weight-semibold;
        font-size: 1em;
        line-height: 100%;
        letter-spacing: 0.15em;
        border: 0;
        border-radius: .5em;
        color: #fff;
        background: #17A1D9;
        text-transform: uppercase;
        box-shadow: 0px 0px 0px rgba(0,0,0,.15);

        margin-left: auto;

        transition: box-shadow .15s ease, background .15s ease;
        will-change: box-shadow, background;

        &:hover {
          cursor: pointer;
          box-shadow: 2px 2px 8px rgba(0,0,0,.2);
          background: rgb(0, 125, 175);
        }
      }

      &--button {
        display: flex;
        align-items: center;

        @include max-width( $breakpoint-lg ) {
          display: none;
        }
      }
    }
  }
}

.park-faq {
  margin-bottom: 2.5em;

  &__menu {
    margin-top: 3em;

    @include max-width( $breakpoint-md ) {
      display: none;
    }

    .menu-button {
      width: 100%;
      height: auto;
      display: block;
      position: relative;
      margin: 1rem 0;
      padding: 1rem 1.5rem 0.9rem;
      font-family: $font-family-secondary;
      font-weight: $font-weight-semibold;
      font-size: 1.25em;
      line-height: 100%;
      border: 0;
      border-radius: .5em;
      color: #fff;
      background: #17A1D9;
      box-shadow: 0px 0px 0px rgba(0,0,0,.15);
      text-align: left;
      outline: none;

      transition: box-shadow .15s ease, background .15s ease, width .2s ease;
      will-change: box-shadow, background;

      &:hover {
        cursor: pointer;
      }

      .icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        fill: #fff;
      }

      &:hover, &--active {
        width: 120%;
        margin-right: -2rem;
        background: rgb(0, 125, 175);
        box-shadow: 2px 2px 8px rgba(0,0,0,.2);

        @include max-width( $breakpoint-lg ) {
          width: 140%;
        }
      }
    }

    &--mobile {
      @include min-width( $breakpoint-md ) {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    position: relative;
    z-index: 2;

    .inner {
      padding: 48px;
      font-size: 1em;
      line-height: 1.375rem;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
      border-radius: 8px;

      @include max-width( $breakpoint-sm ) {
        padding: 32px;
      }

      h1 {
        font-weight: $font-weight-bold;
      }

      a {
        color: $color-link;
        font-weight: $font-weight-semibold;

        &:hover {
          color: $color-link-hover;
        }
      }
    }
  }

  .hidden {
    display: none;
  }
}

.park-pricing {
  &__title, &__subtitle {
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    font-family: $font-family-secondary;
    font-weight: $font-weight-semibold;
    font-size: 1.5em;
    line-height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &__subtitle {
    margin: 0;
    font-size: 1.25em;
    border: 0;
  }

  &__table {
    margin-bottom: 2.5em;

    li {
      display: flex;
      padding-bottom: .5rem;
      font-size: 1em;
      line-height: 1.375rem;

      &:last-child {
        padding-bottom: 0;
      }

      .price {
        text-align: right;
        font-weight: $font-weight-bold;
        padding-right: 1rem;
      }
    }
  }
}

.park-personal {
  &__video {
    border-radius: .5em;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);

    @include max-width( $breakpoint-md ) {
      height: 50vw;
      margin-top: 1.5em;
    }
  }
}

.park-gallery {
  margin: 2.5em auto 7em;

  .image {
    height: 20em;
    margin-bottom: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: .5em;
    overflow: hidden;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);

    transition: box-shadow .15s ease;
    will-change: box-shadow;

    &:hover {
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.2);
    }
  }
}

.select-css {
  display: block;
  font-size: 16px;
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
  line-height: 100%;
  padding: .8rem 1rem .7rem;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 1rem;
  border: 0;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #fff;
  background: #17A1D9;
  box-shadow: 2px 2px 8px rgba(0,0,0,.2);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' fill-rule='evenodd' clip-rule='evenodd' d='M19.2929 7.29289L20.7071 8.70711L12 17.4142L3.29289 8.70711L4.70711 7.29289L12 14.5858L19.2929 7.29289Z' /%3E%3C/svg%3E%0A"),
    linear-gradient(to bottom, #17A1D9 0%,#17A1D9 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: 1em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:focus {
  box-shadow: 2px 2px 8px rgba(0,0,0,.2);
  color: #fff;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

