@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Raleway:300,400,500,600,700,800&display=swap');

/* Includes */
@import 'includes/variables';
@import 'includes/icons';

/* Helpers */
@import 'helpers/mixins';
@import 'helpers/reset';
@import 'helpers/helpers';
@import 'helpers/grid';

/* Parts */
@import 'header';
@import 'footer';
@import 'parts/mobile-nav';

/* Pages */
@import 'pages/front-page';
@import 'pages/page';
@import 'pages/page-park';

body {
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;

  &.fixed {
    overflow: hidden;
  }
}

.main-container {
  position: relative;
  overflow: hidden;
  max-width: $flexboxgrid-max-width + 500px;
  min-height: 100vh;
  margin: 0 auto;
}

p {
  font-size: 1em;
  line-height: 137.5%;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

b {
  font-weight: $font-weight-semibold;
}

a {
  color: inherit;
  text-decoration: none;
  pointer-events: all;

  transition: color .1s ease;
  will-change: color;

  &:hover {
    color: $color-link-hover;
  }
}

.separator {
  position: relative;
  margin: 1em auto 4em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  .icon {
    position: absolute;
    top: 50%; left: 50%;
    padding: 0 2.5em;
    background-color: #fff;
    transform: translateX(-50%) translateY(-50%);
  }

  &--equal-spacing {
    margin: 4em auto 4em;
  }
}

.wrapper {
  @include max-width( $breakpoint-sm ) {
    margin: 0 32px;
  }
}

#click-container {
  display: none;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 500;

  &.active {
    display: block;
  }
}
