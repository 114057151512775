.page {
  &__inner {
    padding: 2rem 0;
  }

  &__title {
    margin-bottom: 1rem;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 2.25em;
    line-height: 120%;

    @include max-width( $breakpoint-md ) {
      font-size: 1.5em;
    }
  }

  &__subtitle {
    font-size: 1.25em;
    line-height: 120%;
    margin-bottom: 4rem;

    @include max-width( $breakpoint-md ) {
      font-size: 1em;
    }
  }

  p {
    line-height: 1.5;
  }

  img.styled {
    width: 100%;
    border-radius: .5rem;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

    &.spacing-bottom {
      margin-bottom: 1.5em;
    }
  }

  .list-styled {
    list-style: initial;

    .block-list-item {
      padding-bottom: .2em;
      margin-left: 1.2rem;
      line-height: 1.5;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .image-text-block {
    align-items: center;
    margin-bottom: 4rem;

    h2 {
      margin-bottom: .5rem;
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      font-size: 1.5em;
      line-height: 120%;

      @include max-width( $breakpoint-md ) {
        font-size: 1.25em;
      }
    }

    .text {
      @include max-width( $breakpoint-md ) {
        margin: 2rem 0;
      }
    }
  }

  .pricing-table {
    width: 100%;
    text-align: center;
    min-width: 900px;
    overflow: hidden;

    @include max-width( $breakpoint-sm ) {
      font-size: 14px;
    }

    th {
      padding: 1em;
      font-weight: $font-weight-bold;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &.important {
        border-bottom: 1px solid rgb(11, 141, 192);
        border-top-left-radius: .5em;
        border-top-right-radius: .5em;
      }
    }

    td {
      padding: 1em;
    }

    tr:last-child {
      .important:last-child {
        border-bottom-left-radius: .5em;
        border-bottom-right-radius: .5em;
      }
    }

    .row__title {
      font-weight: $font-weight-bold;
      text-align: right;
      width: 25%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .important {
      background-color: #17A1D9;
      color: #fff;
    }
  }

  .text-block {
    text-align: center;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    @include max-width( $breakpoint-md ) {
      height: auto;
      margin-bottom: 2rem;
    }

    .inner {
      padding: 3em 2em;

      p {
        margin-top: 1em;
      }
    }

    h2 {
      font-size: 1.5em;
      font-weight: $font-weight-semibold;
      margin-bottom: 2.5rem;
      line-height: 1.5;
    }

    ul {
      flex: 1;
    }

    li {
      margin-bottom: .8em;
    }
  }
}

.overflow-x-scroll {
  overflow-x: scroll;
}
