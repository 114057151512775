.parks {
  height: 100%;

  &__grid {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-areas: "large-park small-park-1" "large-park small-park-2";

    @include max-width( $breakpoint-sm ) {
      display: block;
    }
  }

  .park {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 48px;
    color: #fff;
    background-color: #fff;
    border-radius: .5em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, .15);

    transition: box-shadow .2s ease;
    will-change: box-shadow;

    @include max-width( $breakpoint-sm ) {
      padding: 24px 32px;
      margin-bottom: 1.5rem;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 8px 8px 16px rgba(0, 0, 0, .2);
    }

    &--large {
      grid-area: large-park;

      .park__link {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 3.45%, rgba(0, 0, 0, 0.15) 12.75%, rgba(0, 0, 0, 0) 24.48%, rgba(0, 0, 0, 0) 70.07%, rgba(0, 0, 0, 0.45) 90.06%);

        @include max-width( $breakpoint-sm ) {
          background: rgba(0, 0, 0, 0.55);
        }
      }
    }

    &--small-1 {
      grid-area: small-park-1;

      .park__link {
        background: rgba(0, 0, 0, 0.55);
      }
    }

    &--small-2 {
      grid-area: small-park-2;

      .park__link {
        background: rgba(0, 0, 0, 0.55);
      }
    }

    &__link {
      position: absolute;
      left: 0; top: 0; bottom: 0; right: 0;

      &:hover {
        & + .park__icon {
          right: 40px;
          fill: $color-link;

          @include max-width( $breakpoint-md ) {
            right: 24px;
          }

          @include max-width( $breakpoint-sm ) {
            right: 16px;
          }
        }
      }
    }

    &__icon {
      position: absolute;
      top: 64px; right: 64px;
      fill: #fff;
      pointer-events: none;

      transition: right .2s ease, fill .2s ease;
      will-change: right, fill;

      @include max-width( $breakpoint-md ) {
        top: 48px; right: 32px;
        height: 48px;
      }

      @include max-width( $breakpoint-sm ) {
        top: 48px; right: 24px;
        height: 40px;
      }
    }

    &__title {
      margin-bottom: 1.5rem;
      font-family: $font-family-secondary;
      font-size: 3em;
      font-weight: $font-weight-bold;
      line-height: 115%;
      text-transform: uppercase;
      word-spacing: 100vw;

      @include max-width( $breakpoint-md ) {
        font-size: 2em;
        margin-bottom: 1rem;
      }
    }

    &__location-link {
      @include max-width( $breakpoint-sm ) {
        display: none;
      }
      &:hover {
        svg {
          fill: $color-link-hover;
        }
      }

      svg {
        fill: #fff;
        vertical-align: text-bottom;

        transition: fill .1s ease;
        will-change: fill;
      }
    }

    &__header, &__footer {
      position: relative;
      pointer-events: none;
      z-index: 1;
    }

    &__header {
      margin-bottom: 4em;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      font-family: $font-family-primary;
      line-height: 125%;

      @include max-width( $breakpoint-sm ) {
        font-size: 0.75em;
      }

      p {
        margin: 0;
      }
    }

    a {
      &:hover {
        color: $color-link;
      }
    }
  }

  &--footer {
    .park {
      @include max-width( $breakpoint-md ) {
        margin-bottom: 1.5rem;
      }

      &__title {
        font-size: 2.25em;
        line-height: 2.625rem;
      }

      &__icon {
        top: 48px;
      }
    }
  }
}

.front-page {
  max-height: calc( 100vh - 2.5em );
}
