.header {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0;
    font-family: $font-family-secondary;
    font-weight: $font-weight-semibold;

    @include max-width( $breakpoint-sm ) {
      padding: 24px 0;
    }
  }

  .left {
    display: flex;
    align-items: center;

    .logo {
      margin-right: 64px;

      @include max-width( $breakpoint-sm ) {
        margin-right: 0;

        svg {
          width: 125px;
        }
      }
    }

    .language-switch {
      position: relative;
      color: inherit;
      font-size: 1em;
      line-height: 100%;
      text-decoration: none;

      @include max-width( $breakpoint-sm ) {
        display: none;
      }

      a:hover {
        color: $color-link-hover;

        .icon {
          fill: $color-link-hover;
        }
      }

      .icon {
        vertical-align: bottom;

        transform: rotate(90deg);
        transition: transform .2s ease;
      }

      a.open {
        .icon {
          transform: rotate(0deg);
        }
      }
    }
  }

  .menu {
    display: inline-block;

    @include max-width( $breakpoint-sm ) {
      display: none;
    }

    &--header {
      margin-left: 64px;
    }

    &--social-icons {
      .menu__item {
        margin-right: 16px;
      }
    }

    &__item {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: inherit;
        font-size: 1em;
        line-height: 110%;
        text-decoration: none;

        &:hover {
          color: $color-link-hover;

          .icon {
            fill: $color-link-hover;
          }
        }

        .icon {
          vertical-align: top;

          &--dropdown {
            transform: rotate(90deg);
            transition: transform .2s ease;
          }
        }

        &.open {
          .icon {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  &__dropdown {
    display: none;
    min-width: 100px;
    max-width: 160px;
    position: absolute;
    top: calc( 100% + 1rem );
    left: -1.5rem;
    padding: 1rem 1.5rem;
    background: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    z-index: 1001;

    &--right {
      left: unset;
      right: -1.5rem;
      text-align: right;
    }

    &--reverse {
      top: unset;
      bottom: calc( 100% + 1rem );
    }

    &.open {
      display: block;
    }

    li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .mobile-menu {
    display: flex;
    align-items: center;
    font-size: .75em;
    letter-spacing: 0.15rem;
    text-transform: uppercase;

    @include min-width( $breakpoint-sm ) {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }

    .mobile-menu-icon {
      width: 24px;
      height: 2px;
      position: relative;
      background-color: #000;
      margin-left: .5rem;
      margin-right: 0px;

      transition: width .2s ease, margin-right .2s ease;
      will-change: width, margin-right;

      &:before, &:after {
        content: "";
        width: 24px;
        height: 2px;
        position: absolute;
        background-color: #000;
        transform: rotate(0deg);

        transition: top .2s ease, transform .2s ease;
        will-change: top, transform;
      }

      &:before {
        top: -8px;
      }

      &:after {
        top: 8px;
      }
    }

    &.open {
      .mobile-menu-icon {
        width: 0px;
        margin-right: 24px;

        &:before,
        &:after {
          top: 0;
          border-radius: 1px;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.menu--mobile {
  .menu__item {
    margin-bottom: 1rem;

    .icon {
      transform: rotate(-90deg);
    }
  }
}

.front-page {
  .header__inner,
  .footer__inner {
    padding: 32px 64px;

    @include max-width( $breakpoint-md ) {
      padding: 32px 0;
    }

    @include max-width( $breakpoint-sm ) {
      padding: 24px 0;
    }
  }
}

.page, .content-page {
  .header__inner {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
}
