.footer {
  &__inner {
    display: flex;
    justify-content: space-between;
    padding: 3em 0;
    color: rgba(0, 0, 0, 0.5);

    @include max-width( $breakpoint-md ) {
      flex-direction: column;
      text-align: center;
    }

    .right-menu {
      display: flex;

      @include max-width( $breakpoint-md ) {
        flex-direction: column;
        justify-content: center;

        .social-menu {
          margin-bottom: 1rem;
        }
      }
    }
  }

  ul {
    li {
      vertical-align: middle;
      display: inline-block;
      margin-left: 2rem;

      &.icon-item {
        margin-left: 1rem;
      }

      @include max-width( $breakpoint-md ) {
        &:first-child {
          margin-left: 0;
        }
      }

      .icon {
        fill: rgba(0, 0, 0, 0.5);
      }

      a:hover {
        .icon {
          fill: $color-link-hover;
        }
      }
    }
  }
}

.bg-decorations {
  position: absolute;
  z-index: -1;

  &--header {
    top: -11.25em;
    right: 4em;

    @include max-width( $breakpoint-md ) {
      top: -20em;
      right: 0;
      width: 200px;
    }
  }

  &--footer {
    bottom: 0;
    left: 4em;

    @include max-width( $breakpoint-md ) {
      bottom: 0;
      left: 0;
      width: 300px;
      height: auto;
    }
  }
}
