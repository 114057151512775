
/* Grid */
$container-sm: 808px;
$container-md: 1012px;
$container-lg: 1224px;

$breakpoint-sm: $container-sm + 24px;
$breakpoint-md: $container-md + 48px;
$breakpoint-lg: $container-lg + 48px;

$flexboxgrid-breakpoints:
  sm $breakpoint-sm $container-sm,
  md $breakpoint-md $container-md,
  lg $breakpoint-lg $container-lg;

$flexboxgrid-grid-columns: 12;
$flexboxgrid-gutter-width: 24px;
$flexboxgrid-outer-margin: 24px;
$flexboxgrid-max-width: 1224px;

/* Colors */
$color-link: #0A98D1;
$color-link-hover: rgb(3, 100, 141);

/* Fonts */
$font-family-primary: 'Open Sans', sans-serif;
$font-family-secondary: 'Raleway', sans-serif;

$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;
