@mixin max-width( $max ) {
  $max-width: $max - 1px;

  @media only screen and ( max-width: $max-width ) {
    @content;
  }
}

@mixin min-width( $min ) {
  @media only screen and ( min-width: $min ) {
    @content;
  }
}
