.mobile-nav {
  display: none;
  position: fixed;
  top: 73px; right: 0; bottom: 0; left: 0;
  z-index: 1000;
  padding: 1rem 1.5rem;
  font-weight: $font-weight-semibold;
  font-size: 1.5em;
  line-height: 125%;
  background-color: #fff;
  border-top: 2px solid rgba(0, 0, 0, 0.15);

  &.open {
    display: block;
  }

  &__subtitle {
    margin: 2.5rem 0 1rem 0;
    padding-bottom: .5rem;
    font-size: 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &__bottom {
    position: absolute;
    right: 1.5rem; bottom: 1rem; left: 1.5rem;
    display: flex;
    justify-content: space-between;

    .social-menu {
      display: flex;

      .icon-item {
        margin-left: 1rem;
      }
    }
  }
}
