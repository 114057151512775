.icon {
  &--64 {
    width: 64px;
    height: 64px;
  }

  &--24 {
    width: 24px;
    height: 24px;
  }

  &--16 {
    width: 16px;
    height: 16px;
  }
}
